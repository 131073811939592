import React, { useEffect } from 'react'
import { Box } from '@mui/material'
import Footer from '@talentinc/gatsby-theme-ecom/components/Footer/Footer'
import HeaderV2 from '@talentinc/gatsby-theme-ecom/components/HeaderV2/HeaderV2'
import Layout from '@talentinc/gatsby-theme-ecom/components/Layout'
import { CheckoutTemplate } from '@talentinc/gatsby-theme-ecom/components/NewCheckout/Template'
import { useCheckoutStyles } from '@talentinc/gatsby-theme-ecom/components/NewCheckout/styles'
import { PageDataContextType } from '@talentinc/gatsby-theme-ecom/types/page'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { useBETelemetry } from '../../../../gatsby-theme-ecom/hooks/useTelemetry'

type Props = {
  data: PageDataContextType
}

export default function Index(props: Props) {
  const { classes } = useCheckoutStyles()
  const telemetry = useBETelemetry() // Initialize telemetry hook

  useEffect(() => {
    // Extract the plan_code from the URL path
    const pathSegments = window.location.pathname.split('/')
    const planCode = pathSegments[pathSegments.length - 1] // Assuming plan_code is the last segment

    // Gather all necessary telemetry data
    const telemetryData = {
      event: 'visit_checkout_page',
      properties: {
        plan_code: planCode, // Use extracted plan_code from URL path
      },
    }

    // Track the event using telemetry
    telemetry.track(telemetryData)
  }, []) // Use an empty dependency array to ensure the effect runs only once

  return (
    <Layout pageData={props.data}>
      <Helmet>
        <title>Checkout</title>
      </Helmet>

      <Box className={classes.mainContainer}>
        <Box className={classes.pageContainer}>
          <HeaderV2 noNavigation position="relative" />
          <CheckoutTemplate />
          <Footer />
        </Box>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query Purchase {
    brand: contentfulBrand(name: { eq: "TopResume" }) {
      ...Brand
    }
    headerV2: contentfulHeaderV2(
      brand: { elemMatch: { name: { eq: "TopResume" } } }
    ) {
      ...HeaderV2
    }
    footer: contentfulFooter(brand: { name: { eq: "TopResume" } }) {
      ...Footer
    }
    locales: allLocale(filter: { language: { eq: "en" } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
