import React, { useCallback, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import useGTM from '@elgorditosalsero/react-gtm-hook'
import BlueDotLoader from '@talentinc/gatsby-theme-ecom/components/Loaders/BlueDotLoader'
import { tagPurchaseGTMEvent } from '@talentinc/gatsby-theme-ecom/components/NewUpsell/TagPurchaseEvent'
import { AxiosError } from 'axios'
import { useFormik } from 'formik'
import { navigate } from 'gatsby'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import ReCAPTCHA from 'react-google-recaptcha'
import { toast } from 'react-hot-toast'
import useLocation from 'react-use/lib/useLocation'
import * as Yup from 'yup'
import { isProduction } from '@talentinc/gatsby-theme-ecom/utils/env'
import useBrand from '../../hooks/useBrand'
import { TelemetryHandler, useBETelemetry } from '../../hooks/useTelemetry'
import * as logger from '../../utils/logging'
import { ButtonPayOverTime, affirmCheckout, affirmOpen } from './ButtonPayOverTime'
import { Checkbox } from './Checkbox'
import { CheckoutFormSubmit } from './CheckoutFormSubmit'
import { TextInput } from './TextInput'
import { createAffirmCharge, createBraintreeCharge } from './api'
import { CalculatePricesResponse, OrderResponse } from './types'
import { useDropin } from './useBrainTree'
import { camel2snake, getTimezone, isURLNew } from './utils'

const Schema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string()
    .email('A valid Email Address is required')
    .required('Email Address is required'),
})

const SchemaForSomeoneElse = Schema.concat(
  Yup.object().shape({
    otherFirstName: Yup.string().required('First name is required'),
    otherLastName: Yup.string(),
    otherEmail: Yup.string()
      .email('A valid Email Address is required')
      .required('Email Address is required'),
    otherNote: Yup.string(),
  })
)

type Props = {
  discountToken: string
  planCodes: string[]
  telemetry?: TelemetryHandler
  pricesProducts?: CalculatePricesResponse
  isLoading: boolean
  user?: any
}

const CaptchaV2Fallback = ({
  display,
  onChange,
}: {
  display: boolean
  onChange: (token: string | null) => void
}) => {
  if (!display) return null

  return (
    <ReCAPTCHA
      sitekey="6LddkAMqAAAAACW8YcxOEVY0_gK3cD7NiTk3vgqe"
      onChange={onChange}
    />
  )
}

export const CheckoutForm = (props: Props) => {
  const { pathname, hostname } = useLocation()
  const { flagshipProduct, name: brandName } = useBrand()
  const { planCodes, discountToken, pricesProducts, isLoading, user } = props

  const { sendDataToGTM } = useGTM()
  const telemetry = useBETelemetry()

  const [captchaFallback, setCaptchaFallback] = useState({
    satisfied: true,
    token: '',
  })

  const [isForSomeoneElse, setIsForSomeoneElse] = useState(false)
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [loadingSubmit, setLoadingSubmit] = useState(false)

  const dropin = useDropin('#cc-checkout-section', { pricesProducts })

  const resetDropinInstance = useCallback(() => {
    dropin.unmountDropin()
    setTimeout(dropin.mountDropin, 1000)
  }, [dropin])

  const requestPaymentMethod = async () => {
    try {
      return await dropin.requestPaymentMethod()
    } catch (e) {
      logger.error(e)
      return null
    }
  }

  const getCaptchaV3 = useCallback(async () => {
    try {
      if (captchaFallback.token)
        return {
          token: captchaFallback.token,
          version: 2,
        }

      const captcha = await executeRecaptcha?.('checkout')
      if (!captcha) throw new Error('Captcha not satisfied')

      return { token: captcha, version: 3 }
    } catch (e) {
      logger.error(e)
      return null
    }
  }, [captchaFallback, executeRecaptcha])

  const handleCaptchaV3Denied = () => {
    setCaptchaFallback({
      satisfied: false,
      token: '',
    })
  }

  const handleCaptchaV2Change = (token: string | null) => {
    if (!token) return

    setTimeout(() => {
      setCaptchaFallback({
        satisfied: true,
        token,
      })
    }, 1000)
  }

  const triggerGTMEvent = (payload: OrderResponse) => {
    try {
      if (isProduction) {
        const { gtmData, isNewTransaction } = tagPurchaseGTMEvent(payload, {
          pathname,
          hostname,
        })

        isNewTransaction && sendDataToGTM(gtmData)
      }
    } catch (e) {
      logger.error(e)
      return null
    }
  }

  const getPartnerToken = useCallback(() => {
    const query = new URLSearchParams(window.location.search)

    return query.get('pt') ?? ''
  }, [])

  const getRedirectURL = useCallback(() => {
    const query = new URLSearchParams(window.location.search)

    return query.get('redirect_url') ?? ''
  }, [])

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      otherFirstName: '',
      otherLastName: '',
      otherEmail: '',
      otherNote: '',
    },
    validationSchema: isForSomeoneElse ? SchemaForSomeoneElse : Schema,
    onSubmit: async (values): Promise<void> => {
      try {
        setLoadingSubmit(true)

        const paymentMethod = await requestPaymentMethod()
        if (!paymentMethod) {
          setLoadingSubmit(false)
          return
        }

        const timezone = getTimezone()

        const recaptcha = await getCaptchaV3()
        if (!recaptcha) {
          setLoadingSubmit(false)
          return
        }

        const pt = getPartnerToken()

        const charge = await createBraintreeCharge(
          {
            recaptcha,
            nonce: paymentMethod.nonce,
            deviceData: paymentMethod.deviceData,
            planCodes,
            discountToken,
            customer: {
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email,
              ...(timezone && { timezone }),
            },
            ...(isForSomeoneElse && {
              giftee: {
                firstName: formik.values.otherFirstName,
                lastName: formik.values.otherLastName,
                email: formik.values.otherEmail,
                note: formik.values.otherNote,
              },
            }),
          },
          pt
        )

        // TODO: Remove this after the BE are able to restore giftee data
        if (isForSomeoneElse) {
          localStorage.setItem(
            'giftee' + charge.data.orderId,
            JSON.stringify({
              firstName: formik.values.otherFirstName,
              lastName: formik.values.otherLastName,
              email: formik.values.otherEmail,
              note: formik.values.otherNote,
            })
          )
        }

        triggerGTMEvent(charge.data)

        setTimeout(() => {
          const redirect_url = getRedirectURL()
          setLoadingSubmit(false)

          if (redirect_url) {
            const redirectURL = redirect_url + '?bought=1'
            window?.location?.replace(redirectURL)
          } else {
            const isNew = isURLNew(pathname as string)
            navigate(`${isNew ? '/new/' : '/'}upsell?order=${charge.data.orderId}`)
          }
        }, 1000)
      } catch (e) {
        const status = (e as AxiosError).response?.status
        const errorCode = (e as AxiosError<{ errorCode: string }>).response?.data
          ?.errorCode

        if (errorCode === 'INVALID_CAPTCHA') {
          setLoadingSubmit(false)
          handleCaptchaV3Denied()
          toast.error('Failed to validate captcha - please try again.')
        } else {
          if (status && status >= 500 && status <= 599) {
            resetDropinInstance()
            toast.error('Payment method was not accepted')
          } else {
            toast.error('Unable to complete the purchase')
          }
        }

        logger.error(e)
        setLoadingSubmit(false)
      }
    },
  })

  useEffect(() => {
    resetDropinInstance()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pricesProducts?.totalPrice])

  const onSubmitAffirm = useCallback(async () => {
    telemetry.track({
      event: 'affirm_radio_button_click',
      properties: { event_type: 'checkout_event' },
    })

    // has validation errors
    if (Object.keys(await formik.validateForm()).length > 0) {
      return
    }

    try {
      setLoadingSubmit(true)

      affirmCheckout({
        user: {
          name: `${formik.values.firstName} ${formik.values.lastName}`,
          email: formik.values.email,
        },
        items:
          pricesProducts?.planPrices?.map((i) => ({
            sku: i.planCode,
            unitPrice: i.price,
            displayName: i.planName,
          })) ?? [],
        currency: pricesProducts?.planPrices?.at(0)?.currencyCode || 'USD',
        total: (pricesProducts?.totalPrice ?? 0) * 100,
      })

      affirmOpen({
        onSuccess: async (args) => {
          try {
            const timezone = getTimezone()

            const pt = getPartnerToken()

            const charge = await createAffirmCharge(
              {
                planCodes,
                discountToken,
                checkoutToken: args.checkout_token,
                customer: {
                  email: formik.values.email,
                  firstName: formik.values.firstName,
                  lastName: formik.values.lastName,
                  ...(timezone && { timezone }),
                },
                ...(isForSomeoneElse && {
                  giftee: {
                    firstName: formik.values.otherFirstName,
                    lastName: formik.values.otherLastName,
                    email: formik.values.otherEmail,
                    note: formik.values.otherNote,
                  },
                }),
              },
              pt
            ).catch((e) => {
              if (e?.response?.data?.error === 'INVALID_CAPTCHA') {
                setLoadingSubmit(false)
                handleCaptchaV3Denied()
              }
              throw e
            })

            // TODO: Remove this after the BE are able to restore giftee data
            if (isForSomeoneElse) {
              localStorage.setItem(
                'giftee' + charge.data.orderId,
                JSON.stringify({
                  firstName: formik.values.otherFirstName,
                  lastName: formik.values.otherLastName,
                  email: formik.values.otherEmail,
                  note: formik.values.otherNote,
                })
              )
            }

            const redirect_url = getRedirectURL()

            if (redirect_url) {
              const redirectURL = redirect_url + '?bought=1'
              window?.location?.replace(redirectURL)
            } else {
              const isNew = isURLNew(pathname as string)
              navigate(
                `${isNew ? '/new/' : '/'}upsell?order=${
                  charge.data.orderId
                }&affirm=1`
              )
            }
          } catch (e) {
            toast.error('Unable to complete the purchase')
            logger.error(e)
          } finally {
            setLoadingSubmit(false)
          }
        },
        onFail: (...e) => {
          toast.error('Affirm checkout not completed')
          logger.error(...e)
          setLoadingSubmit(false)
        },
      })
    } catch (e) {
      logger.error(e)
      setLoadingSubmit(false)
    }
  }, [
    getRedirectURL,
    pricesProducts,
    formik,
    telemetry,
    discountToken,
    planCodes,
    getPartnerToken,
    isForSomeoneElse,
    pathname,
  ])

  // [Telemetry]: Handle form blur events
  const onFieldBlur: React.FocusEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      formik.handleBlur(e)

      if (!formik.getFieldMeta(e.target.name).error) {
        telemetry.track({
          event: `${camel2snake(e.target.name)}_added_to_form`,
          properties: { event_type: 'checkout_event' },
        })
      }
    },
    [formik, telemetry]
  )

  // [Telemetry]: Handle Braintree (dropin) events
  useEffect(() => {
    const onBlur = ({ emittedBy, fields }: any) => {
      if (fields[emittedBy].isValid) {
        telemetry.track({
          event: `${camel2snake(emittedBy)}_added_to_form`,
          properties: { event_type: 'checkout_event' },
        })
      }
    }

    const onSelect = ({ paymentOption }: any) => {
      telemetry.track({
        event: 'click_payment_method',
        properties: {
          label: paymentOption, // Capture the selected payment method
        },
      })
    }

    dropin.dropinInstance?.on('card:blur', onBlur)
    dropin.dropinInstance?.on('paymentOptionSelected', onSelect)

    return () => {
      dropin.dropinInstance?.off('card:blur', onBlur)
      dropin.dropinInstance?.off('paymentOptionSelected', onSelect)
    }
  }, [telemetry, dropin.dropinInstance])

  useEffect(() => {
    if (user) {
      formik.setFieldValue('firstName', user.first_name, false)
      formik.setFieldValue('lastName', user.last_name, false)
      formik.setFieldValue('email', user.email, false)
    }
    // eslint-disable-next-line
  }, [user])

  const checkRequiredFieldsForSomeoneElse = useCallback(() => {
    if (formik.values.otherFirstName && formik.values.otherEmail) {
      telemetry.track({
        event: 'fill_order_for_someone_else_required_field',
        properties: {
          entered_name: formik.values.otherFirstName,
          entered_email: formik.values.otherEmail,
          plan_code: planCodes[0], // Assuming the first plan code represents the purchase
        },
      })
    }
  }, [formik.values.otherFirstName, formik.values.otherEmail, telemetry, planCodes])

  return (
    <Box position="relative">
      {(isLoading || loadingSubmit) && <BlueDotLoader variant="padded" />}
      <Box
        px={2}
        py={2}
        style={{
          borderRadius: '6px',
          background: 'white',
          padding: '16px 20px',
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Box display="flex" flexDirection="column">
            <Box
              style={{
                display: 'flex',
                gap: 15,
                marginBottom: 20,
                flexWrap: 'wrap',
              }}
            >
              <TextInput.Wrap>
                <TextInput
                  label="First Name"
                  name="firstName"
                  onChange={formik.handleChange}
                  onBlur={onFieldBlur}
                  value={formik.values.firstName}
                  helperText={formik.errors.firstName}
                />
                <TextInput
                  label="Last Name"
                  name="lastName"
                  onChange={formik.handleChange}
                  onBlur={onFieldBlur}
                  value={formik.values.lastName}
                  helperText={formik.errors.lastName}
                />
              </TextInput.Wrap>
              <TextInput
                label="Email Address"
                name="email"
                type="email"
                onChange={formik.handleChange}
                onBlur={onFieldBlur}
                value={formik.values.email}
                helperText={formik.errors.email}
              />
            </Box>

            <ButtonPayOverTime
              disabled={loadingSubmit || !!!captchaFallback.satisfied}
              style={{ marginBottom: 15 }}
              onClick={onSubmitAffirm}
            />

            {dropin.instanceCreateError ? (
              <div>
                Failed to load payment methods{' '}
                <button type="button" onClick={resetDropinInstance}>
                  retry?
                </button>
              </div>
            ) : null}

            <Box id="cc-checkout-section" />

            <Box mt={2}>
              <Checkbox
                id="isForSomeoneElse"
                value={isForSomeoneElse}
                onChange={() => {
                  setIsForSomeoneElse((i) => !i)

                  // Track the "Order for someone else" checkbox click
                  telemetry.track({
                    event: 'click_order_for_someone_else',
                    properties: {
                      plan_code: planCodes[0], // Assuming the first plan code represents the purchase
                    },
                  })
                }}
                label="Is this order for someone else?"
              />
            </Box>

            {isForSomeoneElse ? (
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 15,
                  marginBottom: 20,
                  marginTop: 10,
                }}
              >
                <TextInput.Wrap>
                  <TextInput
                    label="First name"
                    name="otherFirstName"
                    onChange={formik.handleChange}
                    onBlur={(e) => {
                      formik.handleBlur(e)
                      checkRequiredFieldsForSomeoneElse()
                    }}
                    value={formik.values.otherFirstName}
                    helperText={formik.errors.otherFirstName}
                  />
                  <TextInput
                    label="Last name"
                    name="otherLastName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.otherLastName}
                    helperText={formik.errors.otherLastName}
                  />
                </TextInput.Wrap>
                <TextInput.Wrap>
                  <TextInput
                    label="Email address"
                    name="otherEmail"
                    type="email"
                    onChange={formik.handleChange}
                    onBlur={(e) => {
                      formik.handleBlur(e)
                      checkRequiredFieldsForSomeoneElse()
                    }}
                    value={formik.values.otherEmail}
                    helperText={formik.errors.otherEmail}
                  />
                  <TextInput
                    label="Note to recipient"
                    name="otherNote"
                    onChange={formik.handleChange}
                    value={formik.values.otherNote}
                    helperText={formik.errors.otherNote}
                  />
                </TextInput.Wrap>
              </Box>
            ) : null}
          </Box>
        </form>
      </Box>
      {!!!captchaFallback.satisfied ? (
        <Box display="flex" alignItems="center" justifyContent="center" py={3}>
          <CaptchaV2Fallback
            display={!!!captchaFallback.satisfied}
            onChange={handleCaptchaV2Change}
          />
        </Box>
      ) : (
        <Box mt={2}>
          <CheckoutFormSubmit
            disabled={!!!captchaFallback.satisfied}
            loading={loadingSubmit}
            onClickSubmit={async () => {
              // Run Formik validation on all fields before proceeding
              const errors = await formik.validateForm()

              // Collecting form data and error messages
              const errorTextArray = []

              // Collect Formik validation errors
              if (errors.firstName) errorTextArray.push('First name is required')
              if (errors.lastName) errorTextArray.push('Last name is required')
              if (errors.email) errorTextArray.push('Email Address is required')

              // If "Order for Someone Else" is checked, validate those fields too
              if (isForSomeoneElse) {
                if (errors.otherFirstName)
                  errorTextArray.push("Other person's First name is required")
                if (errors.otherEmail)
                  errorTextArray.push("Other person's Email Address is required")
              }

              // Additional properties for telemetry with flexible typing
              const additionalProperties: Record<string, any> = {
                entered_email: formik.values.email || '',
                entered_first_name: formik.values.firstName || '',
                entered_last_name: formik.values.lastName || '',
              }

              // If there are errors, serialize the errorTextArray and add error_text to additionalProperties
              if (errorTextArray.length > 0) {
                const errorText = JSON.stringify(errorTextArray)
                additionalProperties.error_text = errorText // Add error_text dynamically
              }

              // Tracking complete order click with additional properties
              telemetry.track({
                event: 'click_complete_order',
                properties: {
                  ...additionalProperties, // Merging additional properties
                },
              })

              formik.submitForm()
            }}
            onClickGoBack={() => {
              telemetry.track({
                event: 'back_to_services_click',
                properties: { event_type: 'checkout_event' },
              })

              navigate(`/${flagshipProduct}-writing`)
            }}
            brandName={brandName} // Pass the brandName prop here
          />
        </Box>
      )}
    </Box>
  )
}
